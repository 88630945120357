<template>
    <div class="write">
        <div class="search-box">
            <div>
                <div class="search-one">
                    <p class="left">
                        <span>收款筛选</span>
                        <el-input
                            size="small"
                            style="width: 200px"
                            placeholder="请输入客户名称"
                            v-model="fieldVal"
                            clearable
                        >
                        </el-input>
                        <el-button
                            @click="
                                () => {
                                    currentPage = 1;
                                    pageSize = 20;
                                    getTableData();
                                }
                            "
                            type="primary"
                            size="small"
                            icon="el-icon-search"
                        ></el-button>
                        <!-- <span v-if="btnP.depSearch">部门</span>
                        <el-select
                            v-if="btnP.depSearch"
                            v-model="searchVal.departmentId"
                            size="small"
                            filterable
                            style="width: 120px"
                            clearable
                            placeholder="请选择"
                            @change="changeDepartmentId"
                        >
                            <el-option
                                v-for="item in departmentList"
                                :key="item.id"
                                :label="item.departmentName"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                        <span v-if="btnP.userSearch">成员</span>
                        <el-select
                            v-if="btnP.userSearch"
                            style="width: 120px"
                            v-model="searchVal.adminId"
                            size="small"
                            filterable
                            clearable
                            placeholder="请选择"
                            @change="
                                () => {
                                    currentPage = 1;
                                    pageSize = 20;
                                    getTableData();
                                }
                            "
                        >
                            <el-option
                                v-for="item in userName"
                                :key="item.id"
                                :label="item.nickName"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select> -->
                        <Department
                            ref="department"
                            :departmentWidth="120"
                            :adminWidth="120"
                            :depSearch="btnP.depSearch"
                            :searchUser="btnP.userSearch"
                            @searchData="searchData"
                        ></Department>
                    </p>
                    <p></p>
                </div>

                <div class="search-two">
                    <span>时间</span>
                    <el-date-picker
                        v-model="createDate"
                        type="month"
                        size="small"
                        value-format="yyyy-MM"
                        style="width: 270px"
                        placeholder="请选择年月"
                        @change="
                            () => {
                                currentPage = 1;
                                pageSize = 20;
                                getTableData();
                                getStatistics();
                            }
                        "
                    >
                    </el-date-picker>
                </div>
            </div>
            <div>
                <p>
                    <img src="@/img/qrcodeCollection.png" alt="" /> 月回款金额
                </p>
                <h3>{{ statistics.totalArriveAmount || 0 }}</h3>
            </div>
        </div>
        <div class="table-title">
            <p>二维码收款列表</p>
        </div>
        <el-table
            class="table-box"
            ref="multipleTable"
            :data="tableData"
            key="1"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column prop="" width="10"> </el-table-column>
            <el-table-column label="交易时间" prop="arriveTime" width="160">
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="到账金额"
                prop="arriveAmount"
                show-overflow-tooltip
                width="90"
            >
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="客户名称"
                prop="contractA"
                show-overflow-tooltip
                width="193"
            >
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="入账公司" width="193" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span class="text" v-if="scope.row.typeB != 9">
                        {{ $tableDataHandle.typeB(scope.row.typeB) }}
                    </span>
                    <span class="text" v-if="scope.row.typeB == 9">{{
                        scope.row.contractB
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>

            <el-table-column label="入账方式" show-overflow-tooltip width="100">
                <template slot-scope="scope">
                    <span class="text">
                        {{
                            scope.row.entryMethod == 1
                                ? '对公'
                                : scope.row.entryMethod == 2
                                ? '微信'
                                : scope.row.entryMethod == 3
                                ? '支付宝'
                                : scope.row.entryMethod === 0
                                ? '二维码收款'
                                : '- -'
                        }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="所属人" show-overflow-tooltip width="60">
                <template slot-scope="scope">
                    <span>{{ scope.row.adminName || '- -' }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="所属部门" show-overflow-tooltip width="80">
                <template slot-scope="scope">
                    <el-tooltip placement="top"
                        >·
                        <div slot="content">
                            <p
                                style="line-height: 2"
                                v-for="item in scope.row.departmentAdminDOList"
                                :key="item.id"
                            >
                                {{ item.departmentName }}
                            </p>
                        </div>
                        <em style="font-style: normal">
                            {{ returnName(scope.row) }}
                        </em>
                    </el-tooltip>
                </template>
            </el-table-column>

            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
        <div class="page-box">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
    </div>
</template>
<script>
import { arriveList, statisticsList } from '@/api/user/finance/write';
import { roleList } from '@/api/customer/customer';
import { departmentList } from '@/api/admin/framework/framework.js';
import Department from '../../../../components/Department';
export default {
    components: {
        Department,
    },
    data() {
        return {
            statistics: {},
            field: '',
            fieldVal: '',
            createTime: '',
            createDate: this.getDate(),
            tableData: [],
            pagesize: 20,
            total: 0,
            currentPage: 1,
            userName: [],
            departmentList: [],
            btnP: {},
            searchVal: {
                adminId: '',
            },
        };
    },
    mounted() {
        this.getBtn();
    },
    methods: {
        getDate() {
            var now = new Date();
            var year = now.getFullYear(); //得到年份
            var month = now.getMonth(); //得到月份
            //得到日期
            var hour = ' 00:00:00'; //默认时分秒 如果传给后台的格式为年月日时分秒，就需要加这个，如若不需要，此行可忽略
            month = month + 1;
            month = month.toString().padStart(2, '0');
            var defaultDate = `${year}-${month}`; //
            return defaultDate;
        },
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
            }
            this.btnP = btn;

            this.getTableData();
            this.getStatistics();
            if (btn.depSearch) {
                this.getDepartmentList();
            }
            if (btn.userSearch && !btn.depSearch) {
                this.$refs.department.getUserList(
                    sessionStorage.getItem('departmentId')
                );
            }
        },
        getStatistics() {
            let data = {
                param: { typeB: 0, status: 2 },
            };
            if (this.createDate) {
                data.param.dateParam = this.createDate;
            }
            statisticsList(data).then((res) => {
                this.statistics = res.data;
            });
        },
        getTableData() {
            let data = {
                param: {
                    typeB: 0,
                    status: 2,
                },
                pageNum: this.currentPage,
                pageSize: this.pagesize,
            };
            if (this.fieldVal) {
                data.param.name = this.fieldVal;
            }
            if (this.createDate) {
                data.param.dateParam = this.createDate;
            }
            if (this.btnP.userSearch && !this.btnP.depSearch) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');

                if (this.searchVal.adminId) {
                    data.param.adminId = this.searchVal.adminId;
                }
            }

            if (this.btnP.depSearch) {
                if (this.searchVal.adminId) {
                    data.param.adminId = this.searchVal.adminId;
                }
                if (this.searchVal.departmentId) {
                    data.param.departmentId = this.searchVal.departmentId;
                }
            }
            if (!this.btnP.userSearch && !this.btnP.depSearch) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');
                data.param.adminId = sessionStorage.getItem('adminId');
            }
            arriveList(data).then((res) => {
                this.total = res.data.total;
                this.tableData = res.data.list;
            });
        },
        // 获取部门
        getDepartmentList() {
            let data = {
                param: {},
            };
            departmentList(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.departmentList = res.data;
                    this.$refs.department.getData(this.btnP, res.data);
                }
            });
        },
        searchData(data) {
            this.pagesize = 10;
            this.pageNum = 1;
            this.searchVal.departmentId = data.departmentId;
            this.searchVal.adminId = data.adminId;
            this.getTableData();
        },
        // 创建时间点击
        createitem(e) {
            e === this.createTime
                ? (this.createTime = '')
                : (this.createTime = e);

            this.getTableData();
        },

        // 时间输入框变化
        expireTime() {
            if (this.createDate && this.createDate.length > 0) {
                this.createTime = '';
            }
            this.getTableData();
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getTableData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getTableData();
        },

        searchEmpty() {
            this.field = '';
            this.fieldVal = '';
            this.createTime = '';
            this.createDate = [];
            this.getTableData();
        },
        returnName(data) {
            var str = '';
            data.departmentAdminDOList
                ? data.departmentAdminDOList.forEach((item) => {
                      if (item.main == 1) {
                          str = item.departmentName;
                      }
                  })
                : '';
            return str;
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.write {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .search-box {
        width: 100%;
        margin-bottom: 16px;
        display: flex;
        > div:first-child {
            flex: 1;
            background: #fff;
            margin-right: 12px;
            border-radius: 4px;
        }
        > div:last-child {
            width: 360px;
            background: #fff;
            border-radius: 4px;
            box-sizing: border-box;
            padding: 16px;
            p {
                font-size: 13px;
                font-weight: 600;
                margin-bottom: 10px;
                img {
                    vertical-align: -5px;
                }
            }
            h3 {
                font-weight: 600;
                font-size: 16px;
                padding-left: 22px;
            }
        }
        span {
            font-size: 12px;
            font-weight: 600;
            color: #333333;
            margin: 0 16px;
        }
        .search-one {
            padding: 10px 0 12px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .left {
                button {
                    background: #2370eb;
                    border-radius: 2px;

                    margin-right: 16px;
                }
            }
        }
    }
    .search-two {
        margin-bottom: 12px;
    }
    .table-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 16px;
        .add {
            border-color: #d9d9d9;
            color: #333333;
        }
        p {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
        }

        button {
            font-size: 12px;
            padding: 0 7px 0 12px;
            height: 24px;
            background: #ffffff;
            border-radius: 2px;
            border: 1px solid #2370eb;
            cursor: pointer;
            color: #2370eb;
            margin-left: 16px;
        }

        button:hover {
            background: #2370eb;
            color: #ffffff;
            border-color: #2370eb;
        }
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
